import React, { useEffect } from 'react';

import { NextPageContext } from 'next';
import dynamic from 'next/dynamic';

import useSystem from '../coreSrc/base/systemManager/useSystem';
import { parseCookie } from '../coreSrc/core/js/utils/cookie';
import getScreenInitialProps from '../hostSrc/js/getScreenInitialProps';
import { SEO, isSeoUpdatable } from '../hostSrc/pages/_app/useSeo';
import RichgoLdJSON from '../mapTestSrc/mode/maps/richgoMap/RichgoLdJSON';

const MobilePage_UISet_RichgoMap_Mobile_V2 = dynamic(
  () => import('../mapTestSrc/mode/maps/richgoMap/ui/mobile/MobilePage_UISet_RichgoMap_Mobile_V2'),
  {
    ssr: false,
  }
);
export default function MainPage() {
  const sys = useSystem();
  useEffect(() => {
    if (sys.detector.isMobile) return;
    if (sys.detector.agentStr.indexOf('yeti') > -1) return;
    sys.router.replace('pc');
  }, []);
  // PC인데 Yeti(네이버가 아닌경우만... /pc로 자동으로 이동하게끔...처리...)
  if (!sys.detector.isMobile && sys.detector.agentStr.indexOf('yeti') === -1) return <div />;
  return (
    <>
      <RichgoLdJSON />
      <MobilePage_UISet_RichgoMap_Mobile_V2 />
      {/*<div*/}
      {/*  style={{*/}
      {/*    position: 'fixed',*/}
      {/*    top: 0,*/}
      {/*    left: 0,*/}
      {/*    zIndex: 1000,*/}
      {/*    background: 'pink',*/}
      {/*    fontSize: '11px',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {sys.detector.agentStr}*/}
      {/*</div>*/}
    </>
  );
}
MainPage.getInitialProps = getScreenInitialProps(async (ctx: NextPageContext) => {
  const seo = {
    url: 'https://m.richgo.ai',
    title: SEO.title,
    description: SEO.description,
    customKeywords: SEO.keywords,
  };
  if (ctx.req) {
    //서버사이드
    const accessToken = parseCookie(ctx.req.headers.cookie).RICHGO_API_ACCESS_TOKEN;
    try {
      console.log('seo', seo);
      return { seo, accessToken };
    } catch (error) {
      console.log(error);
    }
  }
  if (isSeoUpdatable(ctx)) {
    console.log('seo2', seo);
    return { seo };
  }
  return {};
});
